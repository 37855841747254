import {useIntl} from 'react-intl';
import * as React from 'react';

interface Props {

}

export const Hero : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();

    const getRandomVideoUrl = () => {
        const random = Math.floor(Math.random() * 4);

        switch (random){
            case 0:{
                return '/video/profilgate-aqua.mp4'
            }
            case 1:{
                return '/video/profilgate-anwendungsfilm.mp4'
            }
            case 2:{
                return '/video/polysto-meatexpo.mp4'
            }
            case 3:{
                return '/video/polysto-2.mp4'
            }
            case 4:{
                return '/video/profilgate-go.mp4'
            }
            default:{
                return '/video/profilgate-aqua.mp4'
            }
        }

    }

    return <>
        <div className="grid grid-nogutter bg-cyan-50 text-800" >
            <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
                <section>
                    <span className="block text-6xl font-bold mb-1">{f({id: 'nkTech'})}</span>
                    <div className="text-4xl text-primary-600 font-bold mb-3">{f({id: 'description'})}</div>
                    {/*<p className="mt-0 mb-4 text-700 line-height-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}

                </section>
            </div>
            <div className="col-12 md:col-6 overflow-hidden" >
                <video loop id={'hero-video'} className="md:ml-auto block md:h-full" width={"100%"} autoPlay muted >
                    <source src={getRandomVideoUrl()} type={"video/mp4"} />
                </video>
            </div>
        </div>
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {Hero} from "./Hero";
import {ProductsSummary} from "./ProductsSummary";
import {Clients} from "./Clients";
import {News} from "./News";

interface Props {

}

export const LandingPage : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();

    return <>
        <Hero />
        <div className="grid md:px-6 lg:px-8 align-items-stretch flex my-6">
            <div className="flex-column col-12 lg:col-8 flex align-items-center justify-content-center col-8 " >
                <ProductsSummary />
            </div>
            <div className="flex-column col-12 lg:col-4 flex col-6 " >
                <News />
            </div>
        </div>
        <Clients />
    </>
};

import * as React from 'react';
import {MouseEventHandler, useState} from "react";
import {useIntl} from "react-intl";
import {Button} from "primereact/button";
import categories from '../../data/categories.json'
import {Category} from "../../lib/types";
import {useNavigate} from "react-router";

interface Props {

}

export const ProductsSummary : React.FC<Props> = props => {
    const [prevCategoryName, setPrevCategoryName] = useState(categories[0]?.name);
    const [image, setImage] = useState(categories[0]?.imageUrl);
    const { locale } = useIntl();

    const {formatMessage : f} = useIntl();
    const navigate = useNavigate();

    const toggleCategory = (categoryName: string) => {
        const el = document.querySelector("#" + categoryName);
        if(!el) return
        el.classList.toggle('surface-border');
        el.classList.toggle('text-blue-900');
        el.classList.toggle('bg-blue-50');
        el.classList.toggle('border-blue-500');
    }


    const handleCategoryHover = (category: Category) => {
        if(prevCategoryName && prevCategoryName === category.name) return;
        if(prevCategoryName)
            toggleCategory(prevCategoryName)
        toggleCategory(category.name);
        setPrevCategoryName(category.name);
        setImage(category.imageUrl);
    }

    const navigateToProducts = () => {
        navigate('/products/' + prevCategoryName)
    }

    return <>
        <div className="surface-section col-12" >
            {/*<div className="text-center font-bold text-primary-600 mb-4 text-2xl">{f({id: 'ourSolutions'})}</div>*/}
            <div className="text-center  text-primary-600 font-bold text-900 mb-5 text-4xl">{f({id: 'products'})}</div>
            <div className="grid">
                <div className="flex-column col-12 lg:col-6 flex align-items-center justify-content-center">
                    <img src={`${image}`} alt="Image" className="w-full md:w-9"/>
                    <Button onClick={navigateToProducts} label={f({id: 'learnMore'})} />
                </div>
                <div className="col-12 lg:col-6 mt-5 lg:mt-0">
                    <ul className="list-none m-0 p-0">
                        {categories.map((el, index) => <li id={el.name} key={el.name} onMouseOver={() => handleCategoryHover(el)} className={`border-left-2 p-4 cursor-pointer ${index === 0 ? 'text-blue-900 bg-blue-50 border-blue-500' : 'surface-border'}`}>
                            <div className="text-2xl font-medium">{f({id: el.name})}</div>
                            <p className="line-height-3 text-xl">{locale === 'bg' ? el.textBg : el.textEn}</p></li>)}
                    </ul>
                </div>
            </div>
        </div>
    </>
};

import * as React from 'react';
import { useIntl } from 'react-intl';
import "./NotFound.css"

interface Props {
}


export const NotFound: React.FC<Props> = (props) => {
    const { formatMessage: f } = useIntl();

    return <>
        <div style={{marginTop: "2rem", marginBottom: "2rem", fontSize: "2rem", fontFamily: "cursive", display: "flex", justifyContent: "center", textAlign: "center"}}>{f({id: 'pageNotFound'})}</div>
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import projects from '../../data/projects.json';
import {Project} from "./Project";

interface Props {

}

export const Projects : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    return <>
        <div className={'p-3'}>
            {projects.map(project => <Project project={project} />)}
        </div>
    </>
};

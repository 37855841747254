import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import news from '../../data/news.json';
import {Article} from "../../lib/types";
import {Dialog} from "primereact/dialog";

interface Props {

}

export const News: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const {locale} = useIntl();

    const [showArticleDialog, setShowArticleDialog] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState<Article>();
    const [articleContent, setArticleContent] = useState<string>();

    const handleArticleClick = (article: Article) => {
        setSelectedArticle(article);
        setShowArticleDialog(true);
        getArticleContent(article);
    }

    const handleArticleDialogHide = () => {
        setShowArticleDialog(false);
        setArticleContent(undefined);
        setSelectedArticle(undefined);
    }

    const articleTemplate = (article: Article) => {
        return <li className="pb-3 border-bottom-1 surface-border cursor-pointer"
                   onClick={() => handleArticleClick(article)}>
            <div className="font-medium text-900 mb-2">{locale === 'bg' ? article.titleBg : article.titleEn}</div>
            <div
                className="line-height-3 text-600 text-overflow-ellipsis white-space-nowrap overflow-hidden">{locale === 'bg' ? article.summaryBg : article.summaryEn}</div>
        </li>
    }

    const getArticleContent = (article: Article) => {
        if (locale === 'bg') {
            if (article.contentBgUrl) {
                fetch(article.contentBgUrl).then(res => res.text()).then(content => {
                    const articleContentElement = document.getElementById("article-content");
                    if (articleContentElement) {
                        articleContentElement.innerHTML = content;
                    }
                })
            } else {
                setArticleContent(article.summaryBg)
            }
        } else {
            if (article.contentEnUrl) {
                fetch(article.contentEnUrl).then(res => res.text()).then(content => {
                    const articleContentElement = document.getElementById("article-content");
                    if (articleContentElement) {
                        articleContentElement.innerHTML = content;
                    }
                })
            } else {
                setArticleContent(article.summaryEn)
            }
        }
    }

    return <>
        <div className="surface-section col-12 overflow-auto" style={{maxHeight: '720px', height: '720px'}}>
            {/*<div className="text-center font-bold text-primary-600 mb-4 text-2xl">{f({id: 'ourSolutions'})}</div>*/}
            <div className="text-center  text-primary-600 font-bold text-900 mb-5 text-4xl">{f({id: 'news'})}</div>
            <ul className="list-none p-0 m-0">
                {news.map(articleTemplate)}
            </ul>
        </div>
        {showArticleDialog && selectedArticle &&
            <Dialog header={locale === 'bg' ? selectedArticle.titleBg : selectedArticle.titleEn}
                    onHide={() => handleArticleDialogHide()} visible={showArticleDialog} style={{width: '30vw'}}
                    breakpoints={{'1920px': '50vw', '1280px': '60vw', '960px': '75vw', '641px': '100vw'}}>
                <div id={'article-content'}></div>
                {articleContent}
            </Dialog>}
    </>
};

import React from 'react';
import './App.css';
import {LandingPage} from "./components/home/LandingPage";
import "primereact/resources/themes/saga-blue/theme.css";          //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import 'primeflex/primeflex.min.css'
import PrimeReact from 'primereact/api';
import products from './data/products.json';
import {BrowserRouter, Routes} from "react-router-dom";
import {Route} from "react-router";
import {Navigation} from "./components/Navigation";
import {Products} from "./components/products/Products";
import {Product} from "./components/products/Product";
import {Footer} from "./components/Footer";
import {Projects} from "./components/projects/Projects";
import {NotFound} from "./components/404/NotFound";
import {About} from "./components/About";

PrimeReact.ripple = true;

const App = () => {

  return <>
    <BrowserRouter>
      {/*<Banner />*/}
      <Navigation />
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path={'products'} element={<Products />} />
        <Route path={'products/:category'} element={<Products />} />
        <Route path={'product/:productId'} element={<Product />} />
        <Route path={'projects'} element={<Projects />} />
        <Route path={'about'} element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  </>
}

export default App;

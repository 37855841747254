import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef, useContext} from 'react';
import {useLocation, useNavigate} from "react-router";
import {LanguageContext} from "../lib/context/LanguageContext";
import categories from '../data/categories.json'

interface Props {
}

export const Navigation: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const prevItem = useRef<keyof typeof routesMap>();
    const didMountRef = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();

    const {setLanguage: setContextLanguage} = useContext(LanguageContext);

    const [prevLanguage, setPrevLanguage] = useState<'bg' | 'en'>();
    const [language, setLanguage] = useState<'bg' | 'en'>();

    const routesMap = {
        'menu-item-home' : '/',
        'menu-item-products' : '/products',
        'menu-item-projects' : '/projects',
        'menu-item-about' : '/about'
    }

    categories.forEach(c => {
        //@ts-ignore
        routesMap['menu-item-' + c.name] = '/products/' + c.name;
    })


    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;

            const language  = localStorage.getItem('language');
            if(language && (language === 'bg' || language === 'en')){
                setLanguage(language)
            }else{
                localStorage.setItem('language', 'bg');
                setLanguage('bg');
            }
        }
    }, []);

    useEffect(() => {
        if(!language) return;
        const current = document.querySelector("#menu-item-" + language);
        const prev = document.querySelector("#menu-item-" + prevLanguage);
        setContextLanguage(language);
        if(current){
            current.classList.toggle('surface-100');
            current.classList.toggle('text-600');
            // current.classList.toggle('text-white');
        }
        if(prev){
            prev.classList.toggle('surface-100');
            prev.classList.toggle('text-600');
            // prev.classList.toggle('text-white');
        }
        setPrevLanguage(language);
    }, [language]);


    useEffect(() => {
        const reversedRoutesMap = Object.keys(routesMap).reduce((acc, el) => {
            //@ts-ignore
            return {...acc, [routesMap[el]] : el}
        }, {});
        //@ts-ignore
        const menuItem = reversedRoutesMap[location.pathname];
        if(menuItem && menuItem !== prevItem.current){
            handleMenuItemClick(menuItem);
            console.log(reversedRoutesMap)
        }
    }, [location.pathname]);


    const toggleMenu = () => {
        const menuWrapper = document.querySelector('#menu-wrapper');
        menuWrapper?.classList.toggle('hidden');
    }

    const toggleProducts = () => {
        const products = document.querySelector('#menu-products');
        products?.classList.toggle('hidden');
    }

    const toggleMenuItem = (id: keyof typeof routesMap) => {
        const el = document.querySelector("#" + id);
        if(!el) return
        el.classList.toggle('surface-100');
        el.classList.toggle('text-600');
        if(!categories.some(c => c.name.includes(id.split('-').at(-1)!)))
            el.classList.toggle('text-white');
        const route = routesMap[id];
        navigate(route);
    }

    const handleMenuItemClick = (id: keyof typeof routesMap) => {
        if(prevItem && prevItem.current === id) return;
        if(prevItem.current)
            toggleMenuItem(prevItem.current)
        toggleMenuItem(id);
        prevItem.current = id;
    }

    const handleLanguageClick = (language: 'bg' | 'en') => {
        if(language === prevLanguage) return;
            localStorage.setItem('language', language)
            setLanguage(language);
    }

    const toggleLanguages = () => {
        const products = document.querySelector('#menu-languages');
        products?.classList.toggle('hidden');
    }

    return <>
        <div
            className="py-3 px-3 shadow-2 flex align-items-center justify-content-between relative lg:static"
            style={{minHeight: "80px", backgroundColor: "#162840"}}><img style={{cursor: 'pointer'}} onClick={() => navigate('/')} src="/nktech-logo.jpg" alt="bastion-700"
                                             className="h-3rem mr-0 lg:h-4rem lg:mr-6"/>
            <a onClick={toggleMenu}
            className="cursor-pointer block lg:hidden text-700"><i className="pi pi-bars text-4xl"></i><span
            role="presentation" className="p-ink"></span></a>
            <div id={'menu-wrapper'}
                className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none">
                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                    <li><a
                        id={'menu-item-home'}
                        onClick={() => handleMenuItemClick('menu-item-home')}
                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"><i
                        className="pi pi-home mr-2"></i><span>{f({id: 'home'})}</span><span role="presentation"
                                                                               className="p-ink"></span></a></li>
                    <li className="lg:relative"
                        onMouseOver={toggleProducts}
                        onMouseOut={toggleProducts}><a
                        id={'menu-item-products'}
                        onClick={() => handleMenuItemClick('menu-item-products')}
                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"><i
                        className="pi pi-clone mr-2"></i><span>{f({id: 'products'})}</span><i
                        className="pi pi-angle-down ml-auto lg:ml-3"></i><span role="presentation"
                                                                               className="p-ink"></span></a>
                        <ul id={'menu-products'} className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                            {categories.map(category => {
                                return <li><a
                                    key={category.name}
                                    id={'menu-item-' + category.name}
                                    onClick={() => {
                                        //@ts-ignore
                                        handleMenuItemClick('menu-item-' + category.name)
                                    }}
                                    className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"><span className="font-medium">{f({id: category.name})}</span><span
                                    role="presentation" className="p-ink p-ink-active"></span></a></li>
                            })}
                        </ul>
                    </li>
                    <li><a
                        id={'menu-item-projects'}
                        onClick={() => handleMenuItemClick('menu-item-projects')}
                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"><i
                        className="pi pi-box mr-2"></i><span>{f({id: 'projects'})}</span><span role="presentation"
                                                                                       className="p-ink"></span></a>
                    </li>
                    <li><a
                        id={'menu-item-about'}
                        onClick={() => handleMenuItemClick('menu-item-about')}
                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"><i
                        className="pi pi-user mr-2"></i><span>{f({id: 'about'})}</span><span role="presentation"
                                                                                      className="p-ink"></span></a></li>
                    <li className="lg:relative"
                        onMouseOver={toggleLanguages}
                        onMouseOut={toggleLanguages}><a
                        id={'menu-item-language'}
                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"><i
                        className="pi pi-language mr-2"></i><span>{f({id: 'language'})}</span><i
                        className="pi pi-angle-down ml-auto lg:ml-3"></i><span role="presentation"
                                                                               className="p-ink"></span></a>
                        <ul id={'menu-languages'} className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                            <li><a
                                id={'menu-item-bg'}
                                onClick={() => handleLanguageClick('bg')}
                                className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full\"><span className="font-medium">{f({id: 'bg'})}</span><span
                                role="presentation" className="p-ink p-ink-active"></span></a></li>
                            <li><a
                                id={'menu-item-en'}
                                onClick={() => handleLanguageClick('en')}
                                className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full"><span className="font-medium">{f({id: 'en'})}</span><span
                                role="presentation" className="p-ink p-ink-active"></span></a></li>

                        </ul>
                    </li>
                </ul>

            </div>
        </div>
    </>
};

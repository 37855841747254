import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Button} from "primereact/button";
import products from '../../data/products.json'
import {Product} from "../../lib/types";
import {useNavigate} from "react-router";

interface Props {
    productId: string;
}

export const ProductCard : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const [product, setProduct] = useState<Product>();
    const { locale } = useIntl();

    const navigate = useNavigate();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        const product = products.find(el => el.productId === props.productId);
        if(!product){
            console.error("Could not find product with productId '" + props.productId + "' in products.json");
        }else{
            setProduct(product)
        }
    }, [props.productId]);


    return <>
        {product !== undefined ?
            <div className="w-full lg:w-6 xl:w-3 p-5"><img src={product.imageUrl}
                                                           alt="Image" className="w-full"/>
                <div className="mt-3 mb-2 font-medium text-900 text-xl">{locale === 'bg' ? product.productNameBg : product.productNameEn}</div>
                <span className="text-700 line-height-3">{locale === 'bg' ? product.summaryTextBg : product.summaryTextEn}</span>
                <a className="text-blue-500 font-medium flex align-items-center mt-2">
                    <Button onClick={() => navigate('/product/' + product.productId)} label={f({id: 'explore'})} className=" p-button-raised"/>
                </a>
            </div>
            :
            null
        }
    </>
};

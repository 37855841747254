import React, {createContext, useState} from 'react';

export const LanguageContext = createContext<any>(null);


export const LanguageContextProvider = (props: any) => {
    const [language, setLanguage] = useState('bg');

    return <>
        <LanguageContext.Provider value={{language, setLanguage}}>
            {props.children}
        </LanguageContext.Provider>
    </>
};

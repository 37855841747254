import * as React from 'react';
import * as bg from './textProperties/bg.json';
import * as en from './textProperties/en.json';
import {IntlProvider} from "react-intl";
import App from "../App";
import {useContext, useState} from "react";
import {LanguageContext} from "./context/LanguageContext";

interface Props {

}

export const AppProxy : React.FC<Props> = props => {
    const {language} = useContext(LanguageContext);

    return <>
        <IntlProvider messages={language === 'bg' ? bg : en} locale={language}>
            <App />
        </IntlProvider>
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Project as IProject} from "../../lib/types";
import {Dialog} from "primereact/dialog";
import {Galleria} from "primereact/galleria";

interface Props {
    project: IProject
}

export const Project : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const { locale } = useIntl();

    const [activeIndex, setActiveIndex] = useState(0);
    const [galleriaVisible, setGalleriaVisible] = useState(false);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const galleriaItems = props.project.images?.map(el => {
        return {
            itemImageSrc: el,
            thumbnailImageSrc: el
        }
    })

    const itemTemplate = (item: { itemImageSrc: string }) => {
        return <img src={item.itemImageSrc} style={{width: '100%'}}/>
    }

    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];

    return <>
        <div className={'text-primary text-4xl font-bold pb-2 border-bottom-1 border-bluegray-300'}>{locale === 'bg' ? props.project.headingBg : props.project.headingEn}</div>
        <div className={'grid mt-5'}>
            {props.project.images.map((imageUrl, index) => {
                return <img className={'col-12 lg:col-3 cursor-pointer'} style={{height: "277px"}}
                            src={imageUrl}
                            onClick={() => {
                                setActiveIndex(index);
                                setGalleriaVisible(true)
                            }}></img>
            })}
        </div>

        {galleriaVisible && <Dialog onHide={() => setGalleriaVisible(false)} visible={galleriaVisible} dismissableMask
                                    breakpoints={{'960px': '75vw', '641px': '100vw'}}>
            <Galleria value={galleriaItems} responsiveOptions={responsiveOptions} numVisible={5} circular
                      showThumbnails={false}
                      style={{maxWidth: '640px'}} onItemChange={(e) => setActiveIndex(e.index)}
                      showItemNavigators item={itemTemplate} activeIndex={activeIndex} itemNextIcon={undefined}
                      itemPrevIcon={undefined} nextThumbnailIcon={undefined} prevThumbnailIcon={undefined}/>
        </Dialog>}
    </>
};

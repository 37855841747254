import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {useParams} from "react-router";
import products from '../../data/products.json';
import {Product as IProduct} from "../../lib/types";
import {Card} from "primereact/card";
import {Galleria} from "primereact/galleria";
import {Dialog} from "primereact/dialog";

interface Props {

}

export const Product: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const {locale} = useIntl();
    let {productId} = useParams();

    const [product, setProduct] = useState<IProduct>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [galleriaVisible, setGalleriaVisible] = useState(false);

    useEffect(() => {
        const product = products.find(el => el.productId === productId);
        if (product) {
            setProduct(product);
        }
    }, [productId]);

    useEffect(() => {
        if (product) {
            const productContentLeft = document.querySelector("#product-content-left");
            const productContentRight = document.querySelector("#product-content-right");
            fetch(locale === 'bg' ? product.contentLeftBgUrl : product.contentLeftEnUrl).then(res => res.text()).then(leftContent => {
                if (productContentLeft) {
                    productContentLeft.innerHTML = leftContent
                }
            })
            fetch(locale === 'bg' ? product.contentRightBgUrl : product.contentRightEnUrl).then(res => res.text()).then(rightContent => {
                if (productContentRight) {
                    productContentRight.innerHTML = rightContent
                }
            })
        }
    }, [product, locale]);

    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];

    const galleriaItems = product?.images.map(el => {
        return {
            itemImageSrc: el,
            thumbnailImageSrc: el
        }
    })

    const itemTemplate = (item: { itemImageSrc: string }) => {
        return <img src={item.itemImageSrc} style={{width: '100%'}}/>
    }

    if (!product)
        return <h1>Product not found</h1>

    return <>
        <div className={'flex justify-content-center mt-8'}>
            <div className={'grid col-12 lg:col-10'}>
                <Card className={'col-12 flex card-fluid'}>
                    <p id={'product-summary'}>{product.showSummaryInProduct && (locale === 'bg' ? product.summaryTextBg : product.summaryTextEn)}</p>
                    <img className={'my-5 max-w-full'} src={product.manufacturerLogo} />

                    <div className={'grid flex-column lg:flex-row'}>
                        <div id={'product-content-left'} className={'col-12 lg:col-6 flex flex-column'}>
                        </div>
                        <div id={'product-content-right'} className={'col-12 lg:col-6 flex flex-column'}>
                        </div>
                    </div>


                    <div className={'grid mt-5'}>
                        {product.images.map((imageUrl, index) => {
                            return <img className={'col-12 lg:col-3 cursor-pointer'} style={{height: "277px"}}
                                        src={imageUrl}
                                        onClick={() => {
                                            setActiveIndex(index);
                                            setGalleriaVisible(true)
                                        }}></img>
                        })}
                    </div>
                </Card>
            </div>
        </div>
        {galleriaVisible && <Dialog onHide={() => setGalleriaVisible(false)} visible={galleriaVisible} dismissableMask
                                    breakpoints={{'960px': '75vw', '641px': '100vw'}}>
            <Galleria value={galleriaItems} responsiveOptions={responsiveOptions} numVisible={5} circular
                      showThumbnails={false}
                      style={{maxWidth: '640px'}} onItemChange={(e) => setActiveIndex(e.index)}
                      showItemNavigators item={itemTemplate} activeIndex={activeIndex} itemNextIcon={undefined}
                      itemPrevIcon={undefined} nextThumbnailIcon={undefined} prevThumbnailIcon={undefined}/>
        </Dialog>}
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Button} from "primereact/button";
import categories from '../data/categories.json'
import '../lib/assets/style/button-template.css'
import {useNavigate} from "react-router";

interface Props {

}

export const Footer: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const menuItems = [
        {
            label: 'home',
            route: '/'
        },
        {
            label: 'products',
            route: '/products'
        },
        {
            label: 'projects',
            route: '/projects'
        },
        {
            label: 'about',
            route: '/about'
        }
    ]

    return <>
        <div className="px-4 py-8 md:px-6 lg:px-8" style={{backgroundColor: "#162840"}}>
            <div className="grid">
                <div className="col-12 md:col-3 flex flex-wrap justify-content-start md:mb-0 mb-3"><img
                    className={'h-3rem'}
                    src="/nktech-logo.jpg" alt="alfred-300"/></div>
                <div className="col-12 md:col-3">
                    <div className="text-white text-lg mb-4 flex flex-wrap" style={{maxWidth: "290px"}}>
                        {f({id: 'address'})}
                    </div>
                    {/*<div className="text-white mb-3"><i className="pi pi-phone border-round p-1 mr-2"></i>(31) 20 779*/}
                    {/*    8986*/}
                    {/*</div>*/}
                    {/*<div className="text-white mb-3"><i className="pi pi-inbox border-round p-1 mr-2"></i>hello@alfred.co*/}
                    {/*</div>*/}
                    <div className="button-demo">
                        <div className={'template col-12 sm:col-5 md:col-12 lg:col-8 xl-col-5'}>
                            <Button onClick={() => window.open('https://www.linkedin.com/company/nk-technology-ltd/')} className="linkedin p-0 w-full" aria-label="LinkedIn">
                                <i className="pi pi-linkedin px-2"></i>
                                <span className="px-2 lg:px-3 text-xs lg:text-base">LinkedIn</span>
                            </Button>
                        </div>
                    </div>
                    <div className="button-demo">
                        <div className={'template'}>
                            <div className={'template col-12 sm:col-5 md:col-12 lg:col-8 xl-col-5'}>
                                <a href={'tel:+359886010172'} className="p-button no-underline linkedin p-0 w-full" aria-label="Phone">
                                    <i className="pi pi-phone px-2"></i>
                                    <span className={'px-2 lg:px-3 text-xs lg:text-base'}>+359886010172</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="button-demo">
                        <div className={'template'}>
                            <div className={'template col-12 sm:col-5 md:col-12 lg:col-8 xl-col-5'}>
                                <a href={'mailto:office@nktech.eu'} className="p-button no-underline linkedin p-0 w-full" aria-label="Mail">
                                    <i className="pi pi-inbox px-2"></i>
                                    <span className={'px-2 lg:px-3 text-xs lg:text-base'}>office@nktech.eu</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-12 md:col-3 text-gray-200">
                    <div className="text-white font-bold line-height-3 mb-3">{f({id: 'products'})}</div>
                    {categories.map(el => {
                        return <a key={'category-' + el.name} className="line-height-3 block cursor-pointer text-white mb-2"
                                  onClick={() => navigate('/products/' + el.name)}>{f({id: el.name})}</a>
                    })}
                </div>
                <div className="col-12 md:col-3 text-gray-200">
                    <div className="text-white font-bold line-height-3 mb-3">{f({id: 'menu'})}</div>
                    {menuItems.map(el => {
                        return <a key={'menu-item-' + el.route} className="line-height-3 block cursor-pointer text-white mb-2"
                                  onClick={() => navigate(el.route)}>{f({id: el.label})}</a>
                    })}

                </div>
            </div>
        </div>
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {Card} from "primereact/card";
import clients from '../../data/clients.json'

interface Props {

}

export const Clients : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    return <>
    <Card className={'w-full'}>
        <div className="font-bold text-primary-600 mb-4 text-2xl">{f({id: 'ourClients'})}</div>
        <div className={'flex flex-wrap justify-content-evenly'}>
            {
                clients.map(client => {
                    return <img key={client.imageUrl} style={{cursor: 'pointer'}} onClick={() => window.open(client.websiteUrl)} src={client.imageUrl} height={'100px'} />
                })
            }
        </div>
    </Card>
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';

interface Props {

}

export const About : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const { locale } = useIntl();

    useEffect(() => {
        const contentDiv = document.querySelector("#about-content");
        fetch(locale === 'bg' ? '/html/about-bg.html' :'/html/about-en.html').then(res => res.text()).then(content => {
            if(contentDiv){
                contentDiv.innerHTML = content
            }
        })
    }, [locale]);

    return <>
        <div id={'about-content'}></div>
    </>
};

import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ProductCard} from "./ProductCard";
import products from '../../data/products.json';
import {useParams} from "react-router";
import {Product} from "../../lib/types";

interface Props {

}

export const Products: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);
    const { category } = useParams();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if(category){
            console.log(category)
            const filtered = products.filter(el => el.category === category);
            setFilteredProducts(filtered);
        }else{
            setFilteredProducts(products)
        }
    }, [category]);


    if(filteredProducts.length === 0){
        return <div style={{marginTop: "2rem", marginBottom: "2rem", fontSize: "2rem", fontFamily: "cursive", display: "flex", justifyContent: "center", textAlign: "center"}}>{f({id: 'noProductsForSelectedCategory'})}</div>
    }

    return <>
        <div className={'flex flex-wrap'}>
            {filteredProducts.map(el => <ProductCard productId={el.productId} key={el.productId} />)}
        </div>
    </>
};
